import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
// import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie"; // Import js-cookie to manage cookies

const colors = {
  background: "#0a0a0a",
  sectionBackground: "#181818",
  accent: "#ffe600", // Yellow accent color
  primaryText: "#ffffff",
  secondaryText: "#ffd966", // Light yellow for secondary text
};

// Fade-in animation for smooth entry of content
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

// Glowing effect for input fields on focus
const glowEffect = keyframes`
  0% { box-shadow: 0 0 5px ${colors.accent}; }
  50% { box-shadow: 0 0 15px ${colors.accent}; }
  100% { box-shadow: 0 0 5px ${colors.accent}; }
`;

// Styled Components
const Container = styled.div`
  width: 250px;
  margin: 2rem auto;
  padding: 3rem;
  background: ${colors.sectionBackground};
  color: ${colors.primaryText};
  border-radius: 12px;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.7);
  animation: ${fadeIn} 0.8s ease-out;
`;

const Title = styled.h1`
  text-align: center;
  color: ${colors.accent};
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Input = styled.input`
  padding: 0.9rem;
  font-size: 1rem;
  border-radius: 8px;
  border: 1px solid #333;
  outline: none;
  background: #2c2c2c;
  color: ${colors.primaryText};
  transition: box-shadow 0.3s ease;

  &:focus {
    animation: ${glowEffect} 1.5s infinite;
  }
`;

const Button = styled.button`
  padding: 0.5rem;
  font-size: 1.1rem;
  font-weight: bold;
  color: ${colors.background};
  background: ${colors.accent};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;

  &:hover {
    background: #ffe600;
    transform: scale(1.05);
    box-shadow: 0px 4px 10px rgba(84, 160, 255, 0.6);
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 5px;
    font-size: 0.9rem;
    color: ${colors.secondaryText};
  }

  input {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #555;
    outline: none;
    background-color: #2c2c2c;
    color: ${colors.primaryText};
  }
`;

const Footer = styled.div`
  margin-top: 1.5rem;
  text-align: center;
  color: ${colors.secondaryText};

  a {
    color: ${colors.accent};
    text-decoration: none;
  }
`;
const PageWrapper = styled.div`
  min-height: 100vh;
  background-color: black; /* Set the background to black */
  display: flex;
  flex-direction: column; // Ensures proper layout with the footer at the bottom
  justify-content: space-between; 
  font-family: 'Poppins', sans-serif;
  color: ${colors.primaryText}; /* Text color to ensure readability */
`;
const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // To display errors if any
  const navigate = useNavigate(); // For navigation

const handleLogin = async (e) => {
  e.preventDefault(); // Prevent default form submission
  setError(""); // Clear previous errors

  try {
    // API endpoint
    const url = "https://qlw95zx5ta.execute-api.eu-north-1.amazonaws.com/t/user/login/authenticate";

    // Request payload
    const payload = {
      email: email,
      password: password,
    };

    // Send the POST request
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload), // Convert payload to JSON string
    });

    // Parse the response
    const data = await response.json();

    if (data.message === "Login successful") {
      // Store the Bearer token in a cookie for 7 days
      Cookies.set("auth_token", data.token, { expires: 7 });

      // Navigate to the dashboard on successful login
      navigate("/dashboard");
    } else {
      // Handle errors from the backend
      setError("Login failed. Please check your credentials.");
    }
  } catch (err) {
    console.error("Error during login:", err);
    setError("An error occurred. Please try again later.");
  }

};
return (
  <PageWrapper>
    <Container>
      <Title>User Login</Title>
      <Form onSubmit={handleLogin}>
        <InputGroup>
          <label>Email</label>
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </InputGroup>
        <InputGroup>
          <label>Password</label>
          <Input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </InputGroup>
        {error && <p style={{ color: "red" }}>{error}</p>} {/* Display error */}
        <Button type="submit">Login</Button>
      </Form>
      <Footer>
        <span>Don't have an account? </span>
        <Link to="/Register">SignUp</Link>
      </Footer>
    </Container>
  </PageWrapper>
);

};

export default LoginPage;
